.cus-container-post {
  position: relative;
  margin: auto;
  border-radius: 8px;
  // padding-bottom: 40px !important;
  max-width: 820px !important;
}

.cus-container-post .edit-my-ad {
  position: absolute;
  right: 0;
  top: 12px;
  font-size: 12px;
  line-height: 14px;
  color: #2f80ed;
  text-decoration: none;
}

@media (max-width: 960px) {
  .cus-container-post .edit-my-ad {
    right: 15px;
  }
}

.post-details-con {
  padding: 25px 20px;
  margin-bottom: 16px;
  border-radius: 8px !important;
}

@media (max-width: 575px) {
  .post-details-con {
    padding: 25px 15px;
  }
}

.change-view-profile {
  position: absolute;
  top: -15px;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1;
  border-radius: 5px;
  padding: 9px 30px 8px 10px;
  border: 0;
  cursor: pointer;
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: textfield;
  width: fit-content;
  min-width: 145px;
  background: url("data:image/svg+xml;utf8,<svg fill='black' height='24' viewBox='0 0 24 24' width='24' xmlns='http://www.w3.org/2000/svg'><path d='M7 10l5 5 5-5z'/><path d='M0 0h24v24H0z' fill='none'/></svg>")
    #bdbdbd no-repeat calc(100% - 5px) !important;
}

.change-view-profile:focus {
  outline: 0;
}

.change-view-profile:hover {
  background: url("data:image/svg+xml;utf8,<svg fill='black' height='24' viewBox='0 0 24 24' width='24' xmlns='http://www.w3.org/2000/svg'><path d='M7 10l5 5 5-5z'/><path d='M0 0h24v24H0z' fill='none'/></svg>")
    rgb(148, 148, 148) no-repeat calc(100% - 5px) !important;
  color: #000;
}

.post-details-con .applicant-photo {
  width: 48px;
  height: 48px;
  border: 1px solid #cca326;
  border-radius: 50%;
}

.post-details-con .name {
  font-weight: 500;
  font-size: 28px;
  line-height: 33px;
  margin-left: 71px;
  margin-top: -56px;
  margin-bottom: 20px;
}

.post-details-con .location {
  margin: 0;
  color: #333333;
  font-size: 14px;
  line-height: 16px;
  margin-left: 71px;
  margin-top: -20px;
}

.createdby-label {
  display: block;
  color: #000;
  font-size: 11px;
  line-height: 8px;
  padding: 6px 9px;
  background: #97f1fd;
  width: fit-content;
  border-radius: 24px;
  margin-left: 71px;
  margin-top: 5px;
}

.verified-label {
  display: block;
  color: #fff;
  font-size: 11px;
  line-height: 8px;
  padding: 6px 9px;
  background: #2979ff;
  width: fit-content;
  border-radius: 24px;
  margin-left: 71px;
  margin-top: 5px;
}

.post-details-con .post-date-col {
  position: absolute;
  display: inline-block;
  width: 50px;
  top: 25px;
  right: 20px;
}

.post-details-con .date-of-post {
  margin: 0;
  font-size: 12px;
  color: #7b7b7b;
}

.post-details-con .date-of-post img {
  width: 2em !important;
}

.post-details-con .likes-you {
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  color: #f54646;
  margin-left: 73px;
  margin-top: 5px;
  margin-bottom: 0;
}

.post-details-con .action-btn {
  display: block;
  width: 100px;
  background: #000000;
  padding: 7px 15px;
  color: #ffffff;
  text-align: center;
  border: 0;
  border-radius: 0.25rem;
  margin-top: 15px;
  margin-left: 71px;
  font-weight: 500;
}

.photos {
  width: 120px;
  height: 120px;
  margin: 25px 15px 10px 0;
  object-fit: cover;
  cursor: zoom-in;
  border-radius: 5px;
}

.post-details-con .desc {
  font-size: 14px;
  line-height: 20px;
  word-wrap: break-word !important;
}

.post-details-con .desc-admin {
  font-size: 14px;
  line-height: 20px;
  word-wrap: break-word !important;
  cursor: pointer;
}

.post-details-con .desc-link {
  line-height: 15px;
  text-decoration: underline;
  cursor: pointer;
  font-size: 12px;
  line-height: 8px;
  margin-left: 71px;
  margin-top: 5px;
}

.post-details-con .heart-icon {
  width: 16px;
  vertical-align: middle;
}

.post-details-con .invisible-photos {
  margin: 20px 0 25px 0;
}

.post-details-con .heading {
  font-family: "Ubuntu", sans-serif !important;
  font-weight: 500;
  font-size: 18px;
  line-height: 21px;
}

.post-details-con .review-sub-sec-heading {
  padding-left: 0;
  font-size: 14px;
  line-height: 16px;
  font-weight: 500;
}

.post-details-con .rev-col-left,
.post-details-con .rev-col-right {
  padding-left: 0;
}
.post-details-con .rev-col-left {
  padding-right: 15px;
}
.post-details-con .rev-col-right {
  padding-left: 15px;
}

@media (max-width: 575px) {
  .post-details-con .rev-col-left {
    padding-right: 0;
  }

  .post-details-con .rev-col-right {
    padding-left: 0;
  }

  .mob-occu-row {
    background: #fff !important;
  }

  .abnormal-col .con-row:nth-child(odd) {
    background: #fff !important;
  }

  .abnormal-col .con-row:nth-child(even) {
    background: #f2f2f2 !important;
  }
}

/* review */
.review-table {
  width: 80% !important;
  margin: auto;
}

.review-sec-heading {
  font-size: 1.25rem;
  font-weight: normal;
  font-size: 20px;
  line-height: 23px;
  padding-bottom: 0 !important;
  margin-bottom: 0 !important;
  margin-top: 10px;
}

.edit-add {
  color: #c38c0e;
  font-size: 14px;
  text-decoration: none;
  font-weight: normal;
  margin-left: 5px;
  vertical-align: middle;
}

.edit-add:hover {
  color: #694d0b;
}

.review-sub-sec-heading {
  font-weight: normal;
  font-size: 16px;
  line-height: 19px;
  margin-top: 30px;
  margin-bottom: 10px;
  padding-left: 30px;
}

.review-col {
  display: inline-block;
  width: 50%;
  padding-left: 30px;
  box-sizing: border-box;
  vertical-align: top;
}

.review-col .con-row {
  width: 100%;
}

.review-col .con-row:nth-child(odd) {
  background: #f2f2f2;
}

.review-col .con-row .con-col {
  display: inline-block;
  vertical-align: top;
  box-sizing: border-box;
  padding: 10px;
}

.review-col .con-row .con-col:nth-child(1) {
  width: 40%;
  text-align: right;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;

  color: #333333;
}

.review-col .con-row .con-col:nth-child(2) {
  width: 60%;
  font-size: 14px;
  line-height: 16px;
  color: #333333;
}

.desktop-mother-col {
  display: inline-block;
}

.mobile-mother-col {
  display: none;
}

@media (max-width: 575px) {
  .review-sub-sec-heading {
    padding-left: 0;
  }

  .review-col {
    width: 100%;
    padding-left: 0;
  }

  .desktop-mother-col {
    display: none;
  }

  .mobile-mother-col {
    display: inline-block;
  }
}

/* respond */
.respond-ways {
  border-radius: 6px;
  font-size: 14px;
  line-height: 16px;
  color: #000000;
  margin-top: 15px;
}

.respond-ways tr td:nth-child(1) {
  width: 40px;
  padding: 0 0 0 15px;
}

.respond-ways tr td:nth-child(2) {
  padding: 0;
}

.respond-ways tr td {
  vertical-align: top;
}

.respond-ways i {
  display: inline-block;
  font-size: 20px;
  margin-right: 20px;
}

.email-main-container{
  display:flex;
  flex-direction: row;
  column-gap: 4px;
}

.id-vrified-badge {
  color: #2979ff;
  margin-top: 15px;
}

.delete-icon-container{
  display: flex;
  align-items: flex-end;
  padding-left: 8px;
  padding-bottom: 2px;
}
.delete-icon{
 color: #7b7b7b;
 cursor: pointer;
}

.last-online-block{
  padding: 6px 0 0 71px;
  font-size: 12px;
}

.last-online-status{
  font-weight: 600;
}

.search-criteria-container{
  width: 48% !important;
  padding: 4px;
}
.search-criteria-label{
  width: 40%;
  text-align: right;
  padding: 4px;
  font-weight: 500  !important;
}

@media screen and (min-width: 601px){
  .preferred-search-grid > div:nth-child(4n-2) {
    background-color: #f2f2f2;
  }
  
  .preferred-search-grid > div:nth-child(4n-3) {
    background-color: #f2f2f2;
  }
}

@media screen and (max-width: 600px){
  .preferred-search-grid > div:nth-child(odd) {
    background-color: #f2f2f2 !important;
  }
  .search-criteria-container{
    width: 100% !important;
  }
}
