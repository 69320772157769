.content-col {
  width: calc(100% - 245px);
  background: #fff;
  padding-bottom: 40px;

  .inner-content {
    padding: 20px 15px 20px 15px;
  }

  .invoice-sec-heading {
    font-weight: 500;
    font-size: 14px;
    margin-bottom: 5px;
  }

  .ad-form-sec {
    max-width: 700px;
    margin: auto;
    margin-top: 20px;
    margin-bottom: 20px;

    .header {
      background: #d9ecfa;
      color: #000;
      padding: 10px 15px;
      margin: 0;
      border: 1px solid #b2c8ee;
      border-radius: 5px 5px 0 0;
    }

    .content {
      padding: 25px 15px 0 15px;
      border: 1px solid rgb(226, 226, 226);
      background: #fff;
      box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
    }
  }

  .info-desc {
    font-size: 13px;
    font-weight: 300;
    margin-bottom: 0;
  }
}

@media (max-width: 768px) {
  .content-col {
    width: 100%;
  }
}

.btn-save {
  background: #28a745;
  color: #fff;
  padding: 5px 25px;
  border: 0;
  margin-bottom: 10px;
}

// table
.common-table thead {
  background: #323d51;
  color: #fff;
  font-size: 14px;
  font-weight: 300;
}

.common-table thead th {
  font-weight: 400;
}

.common-table > tbody > tr:hover {
  background: rgb(245, 245, 245);
}

.common-table tr td {
  font-size: 13px;
  vertical-align: middle;
}

.common-table tr td i {
  cursor: pointer;
  padding: 6px;
  border-radius: 50%;
  font-size: 13px;
}

.common-table tr td .fa-trash-alt {
  border: 1px solid #dc3545;
  background: #fff;
  color: #dc3545;
}

.common-table tr td .fa-trash-alt:hover {
  background: #dc3545;
  color: #fff;
}
.common-table tr td .fa-trash-disabled {
  border: 1px solid #9e9e9e;
  background: #fff;
  color: #9e9e9e;
}

.common-table tr td .fa-trash-disabled:hover {
  border: 1px solid #9e9e9e;
  background: #fff;
  color: #9e9e9e;
  cursor: default;
}

.common-table tr td .add-comment-btn {
  border: 1px solid #616161;
  background: #fff;
  color: #616161;
}

.common-table tr td .add-comment-btn:hover {
  background: #616161;
  color: #fff;
}

.common-table .form-photo-links {
  cursor: pointer;
}

.status {
  display: inline;
  border-radius: 17px;
  font-size: 11px;
  line-height: 11px;
  padding: 3px 8px;
  color: #151b26;
}

.status.live {
  background: #19f047;
}

.status.reviewing {
  background: #ffa800;
}
.status.disabled {
  background: #bbbbbb;
}
.status.expired {
  background: #f18d8d;
  color: #fff;
}
.status.pending {
  background: #ecce6b;
}

.common-table .from-now {
  border-radius: 17px;
  font-size: 10px;
  line-height: 11px;
  color: #666666;
  margin-bottom: 0;
}

.disable-archive-action-btn {
  &:focus {
    box-shadow: none;
  }
}

.disable-archive-action-btn i {
  color: rgb(156, 156, 156);
  font-size: 16px !important;

  &:hover {
    color: #333333;
  }
}

.disable-archive-action-btn.dropdown-toggle::after {
  display: none !important;
}

// tooltip
.tooltip-cus {
  font-weight: 500;
  opacity: 1 !important;
}

// pagination
.pagination-div {
  width: fit-content;
  margin: auto;
  margin-top: 10px;
}

.pagination-div .MuiPaginationItem-page.Mui-selected {
  background-color: #14181f;
  color: #fff;
}

.pagination-div .MuiPaginationItem-page.Mui-selected:hover {
  background-color: #1f1f1f;
}

.pagination-div .MuiPaginationItem-page:hover {
  background-color: #0000001f;
}

.pagination-div button:focus {
  outline: 0;
}

.pagination-div .MuiPaginationItem-root {
  height: 26px;
  min-width: 26px;
  line-height: 1.5rem;
  font-size: 13px;
}

/* notification */
.notification {
  opacity: 1 !important;
  margin-top: 130px !important;
}

.notification-success {
  background-color: #35c835 !important;
}

.virtual-agent-clear-all-div {
  max-width: 700px;
  margin: auto;
  margin-bottom: -16px;
}

.virtual-agent-clear-all-div .clear-all-txt {
  font-size: 13px;
  color: #1566c0;
  text-decoration: underline;
  cursor: pointer;
}

.auto-complete-city .MuiFormControl-marginNormal {
  margin-top: 0;
}

.auto-complete-city .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"] {
  padding: 0;
}

.kpi-tot-col {
  background-color: #f2f2f2;
}

.monthly-kpi-row {
  border-bottom: solid 2px #6c757d;
}
