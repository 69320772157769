.modal-header {
  background: #323d51;
  color: #fff;
}
.parent-modal-main-btn-container {
  padding: 10px;
}
.close-btn{
  margin-right: 20px;
}

.review-header-row{
  align-items: center;
  padding-left: 10;
  column-gap: 10px;
}

.review-gender-img{
  width: 50px;
  border: 1px solid #cca326;
  border-radius: 50%;
}

.font-weight-light{
  font-weight: 300;
}

.review-container{
  background-color: #f2f2f2;
  border-radius: 5px;
  padding: 10px;
  row-gap: 10px;
}

.comment-section{
  overflow: hidden;
  padding: 8px;
  border: 1px solid gray;
  background-color: #fff;
  width: 70%;
  height: 100px;
  border-radius: 8px;
  align-items: center;
  max-height: 150px;
}

.review-text{
  overflow: auto;
  height: 100%;
  width: 100%;
  font-size: 13px;
}

.review-heading{
  font-weight: 600 !important;
  font-size: 15px !important;
}

.profile-pdf-export-modal-title{
  background-color: #14181f;
  color: #fff;
}

.profile-pdf-export-modal-content{
  margin-top: 8px;
  row-gap: 16px;
}

.profile-pdf-export-modal-action{
  background-color: #14181f;
}

.pdf-data-section{
  padding: 0 20px 0 20px;
}

.pdf-data-section-heading{
  padding: 0px 20px 0px;
  font-size: 18px !important;
  font-weight: 600 !important;
}

.pdf-data-section div:nth-child(odd){
  background-color: #f2f2f2;
}


.pdf-data-left-column{
  width: 40%;
  text-align: right;
  padding: 10px;
  font-size: 14px !important;
  font-weight: 500 !important;
}

.pdf-data-right-column{
  width: 60%;
  font-size: 14px !important;
  padding: 10px;
}

@media print{
  .pdf-data-left-column{
    font-size: 18px !important;
  }

  .pdf-data-right-column{
    font-size: 18px !important;
  }
}