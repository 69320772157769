.review-row {
  font-size: 14px;

  .font-weight-300 {
    font-weight: 300;
  }

  .background-dark {
    background: #ececec;
  }
}

.widget-filter-container {
  padding: 10px 20px;
  column-gap: 16px;
}

.widget-filter-btn {
  color: white !important;
  background-color: black !important;
  text-transform: capitalize !important;
}

.widget-filter-btn:disabled {
  background-color: #d5dbdb !important;
  color: white !important;
  text-transform: capitalize !important;
}

.header-table-cell {
  text-align: center !important;
}

.header-total-table-cell {
  text-align: center !important;
  background-color: lightgrey;
}

.body-total-count-cell {
  text-align: center !important;
  font-weight: bold !important;
  background-color: grey;
}
.body-criteria-cell {
  text-align: center !important;
  text-transform: capitalize;
}

.body-total-row{
  background-color: lightgrey;
  & .body-total-cell {
    text-align: center;
    font-weight: bold;
  }
}

.graph-header{
  display: flex;
  align-items: center;
  gap: 1rem;
}

.graph-date-picker{
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 0.5rem;
}

.graph-duration-picker{
  min-width: 9rem;
}

.frequency-btn {
  border: none !important;
  color: #333333 !important;
}

.frequency-btn-active {
  color: red !important;
}

@media (max-width: 1700px) and (min-width:1366px){
  .graph-date-picker{
    display: flex;
    flex-direction: column;
    align-items:end;
    row-gap: 0.5rem;
  }
}

@media (max-width: 1366px) {
  .graph-header{
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    justify-content: center;
    row-gap: 0.5rem;
  }

  .graph-date-picker{
    display: flex;
    flex-direction: row;
  }
}