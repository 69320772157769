.date-picker-container {
//   background-color: lightgreen;
  width: auto !important;
  column-gap: 8px;
  align-items: center;
}

.label {
  font-size: medium !important;
  color: #2c3e50 !important;
  font-weight: 500 !important;
}

.picker-input {
  border: 1px solid black;
  border-radius: 5px;
}
